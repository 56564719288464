import React, { useRef, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import { isMobile } from "react-device-detect";
const App = () => {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/p/:postId" component={AppLink} />
          <Route path="/" component={Home} />
          <Route path="*" component={Home} />
        </Switch>
      </div>
    </Router>
  );
};

const Home = () => {
  return (
    <div className="banner">
      <h2>Ummah App</h2>
    </div>
  );
};

const AppLink = () => {
  const { postId } = useParams();
  const linkRef = useRef();

  useEffect(() => {
    if (isMobile) {
      linkRef.current.click();
    }
  }, []);

  const renderContent = () => {
    if (isMobile) {
      return (
        <div className="mobile_applink">
          <h2>Please wait...</h2>
          <p>
            Redirection not working,{" "}
            <a href={`thisummah://p/${postId}`} ref={linkRef}>
              open this link in <span>Ummah app</span>
            </a>{" "}
            .
          </p>
          <p>
            Don't have the <span>Ummah</span> app installed?{" "}
            <a href="https://play.google.com/store/apps/details?id=com.thisummah.mobile">
              Download UmmahApp for Android
            </a>
          </p>
        </div>
      );
    }
    return (
      <div className="applink">
        <h2>UmmahApp is not available on the web.</h2>
        <p>Open this url from your mobile device to access this resource.</p>
        <p>
          Don't have the UmmahApp app yet?{" "}
          <a href="https://play.google.com/store/apps/details?id=com.thisummah.mobile">
            Download UmmahApp from Playstore
          </a>
        </p>
      </div>
    );
  };
  return renderContent();
};

export default App;
